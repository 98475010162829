import {useMemo} from "react";
import {useAppSelector} from "../../_store/hooks";
import {ROLES} from "../../data-access/fetch-db-users";

export default function useCurrentUserRole() {
	const currentUserInfo = useAppSelector(state => state.user.userInfo);

	const value = useMemo(() => {
		let activeRole = null;

		if (!currentUserInfo) {
			return activeRole;
		}

		const {admin, master_editor, editor, partner, sponsor, influencer, customer_service, marketing, corporate} = currentUserInfo;
		const availableRoles = Object.entries({
			admin,
			master_editor,
			editor,
			partner,
			sponsor,
			influencer,
			customer_service,
			marketing,
			corporate,
		}) as [ROLES, boolean | null][];

		for (let [role, status] of availableRoles) {
			if (status) {
				activeRole = role;
			}
		}

		return activeRole;
	}, [currentUserInfo]);

	return value;
}
