import {zodResolver} from "@hookform/resolvers/zod";
import {useState} from "react";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../_store/hooks";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Image as ImageIcon} from "../../components/primitives/icons";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {auth} from "../../firebase";
import {toast} from "react-hot-toast";
import MultimediaImagePickerModal from "../../components/blocks/MultimediaImagePickerModal";
import {galleryActions} from "../../_store/features/gallery/gallery-slice";
import {InsertShortsDataBodyType, InsertShortsParamListBodySchema, uploadShortsImage} from "../../data-access/shorts/shorts";
import {shortsActions} from "../../_store/features/shorts/shorts-slice";
import Select from "react-select";
import {selectGlobalStyles} from "../../../utils/selectGlobalStyles";
import MuxVideoCombobox from "../series/Edit/subcomponents/MuxVideoCombobox";
import {DayPicker} from "../../components/blocks/DatePicker";
import {FOLDERS_NAMES_SHORTS} from "../../../utils/folderNames";

const initialState: InsertShortsDataBodyType = {
	title: "",
	slugs: "",
	order: 0,
	ranking: 0,
	estreno: true,
	short_description: "",
	long_description: "",
	video_id: "",
	duration: 0,
	lowres_image: "",
	rectangular_image: "",
	release_date: new Date().toISOString(),
};

type ImageFieldsTypes = keyof Pick<InsertShortsDataBodyType, "lowres_image" | "rectangular_image">;

interface SelectOption {
	label: string;
	value: boolean;
}

const selectStatus: SelectOption[] = [
	{label: "Sí", value: true},
	{label: "No", value: false},
];

function ShortsNew() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [fieldLoading, setFieldLoading] = useState<ImageFieldsTypes[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [type, setType] = useState<ImageFieldsTypes>("lowres_image");
	const [selectedStatus, setSelectedStatus] = useState<SelectOption | null>(selectStatus[0]);

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: {errors, isLoading, isSubmitting},
		control,
	} = useForm<InsertShortsDataBodyType>({
		resolver: zodResolver(InsertShortsParamListBodySchema),
		defaultValues: initialState,
	});

	const onSubmit: SubmitHandler<InsertShortsDataBodyType> = data => {
		return dispatch(shortsActions.insertShort(data)).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Short creado correctamente");
				reset();
				setTimeout(() => {
					navigate("/short_videos/list");
				}, 3000);
			}
			if (res.meta.requestStatus === "rejected") {
				if (res.meta.rejectedWithValue && typeof res.payload === "string") {
					toast.error(res.payload);
				}
			}
		});
	};

	const handleUploadImage = (file: any, type: ImageFieldsTypes) => {
		const body = new FormData();
		setFieldLoading(prev => [...prev, type]);

		body.append("file", file);
		body.append("type", type);
		body.append("short_videosid", crypto.randomUUID());
		auth.currentUser
			?.getIdToken()
			.then(token => {
				uploadShortsImage(token, body)
					.then(res => {
						setValue(type, res.data.result);
						setFieldLoading(prev => prev.filter(el => el !== type));
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleSelectImage = (type: ImageFieldsTypes) => {
		setType(type);
		setOpenDialog(true);
	};

	return (
		<section>
			<div className="px-5 py-4">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Nuevo Short</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear shorts que se mostraran en toda la plataforma.</span>
			</div>
			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Titulo<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar titulo del short..." {...register("title")} />
									{errors?.title?.message && <span className="text-sm font-medium text-destructive">{errors?.title?.message}</span>}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Slug<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar slug del short..." {...register("slugs")} />
									{errors?.slugs?.message && <span className="text-sm font-medium text-destructive">{errors?.slugs?.message}</span>}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Orden</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										placeholder="Costo de Producción de la serie..."
										{...register("order", {valueAsNumber: true})}
									/>
									{errors?.order?.message && <span className="text-sm font-medium text-destructive">{errors?.order?.message}</span>}
								</dd>
							</div>

							<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Estreno</dt>
								<Controller
									name="estreno"
									control={control}
									render={({field}) => (
										<Select
											className="mt-1 w-full rounded ring-1"
											styles={selectGlobalStyles}
											{...field}
											options={selectStatus as any}
											value={selectedStatus}
											onChange={selectedOption => {
												setSelectedStatus(selectedOption);
												field.onChange(selectedOption?.value);
											}}
										/>
									)}
								/>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción corta</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input type="text" placeholder="Insertar descripción corta del short..." {...register("short_description")} />
									{errors?.short_description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.short_description?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Descripción larga</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea rows={3} placeholder="Descripción del short..." {...register("long_description")} />
									{errors?.long_description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.long_description?.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Video ID</dt>
								<dd className="mt-1  text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<MuxVideoCombobox
										id="video_id"
										name="video_id"
										defaultValue={getValues("video_id") ?? ""}
										onChangeSelected={selected => {
											setValue("video_id", selected.playbackid);
											setValue("duration", selected.duration);
										}}
										video_type={3}
									/>
									{errors?.video_id?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.video_id.message}</span>
									)}
								</dd>
							</div>

							<div className="justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Fecha de release</dt>
								<dd className="mt-1 w-full justify-start self-start">
									<Controller
										name="release_date"
										control={control}
										render={({field}) => (
											<DayPicker
												{...field}
												onDayChange={date => {
													const dateFormatted = date.toISOString();
													field.onChange(dateFormatted);
												}}
												className="min-w-[150px] justify-start self-start"
											/>
										)}
									/>
									{errors?.release_date?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.release_date?.message}</span>
									)}
								</dd>
							</div>

							<div className="scroll-m-20 pb-4 pt-12 text-2xl font-semibold tracking-tight">Imágenes</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Imagen del Short</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("rectangular_image") ? (
												fieldLoading.includes("rectangular_image") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("rectangular_image")} className="w-full object-contain" alt="Imagen del short" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "rectangular_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="mt-2 gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("rectangular_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.rectangular_image?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.rectangular_image.message}</span>
									)}
								</dd>
							</div>

							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">Thumbnail</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<div className="grid w-full max-w-sm items-center gap-1.5">
										<div className="flex aspect-square w-full items-center justify-center rounded-md border border-border bg-slate-100 p-2 md:w-1/2">
											{!getValues("lowres_image") ? (
												fieldLoading.includes("lowres_image") ? (
													<Spinner />
												) : (
													<ImageIcon className="h-6 w-6 text-slate-400" />
												)
											) : (
												<img src={getValues("lowres_image")} className="w-full object-contain" alt="Thumbnail" />
											)}
										</div>
										<div className="flex w-full flex-col">
											<Input
												type="file"
												onChange={e => {
													const [file] = e.target.files as any;
													handleUploadImage(file, "lowres_image");
												}}
												placeholder="Elige un Archivo"
											/>
											<Button
												variant="outline"
												type="button"
												className="mt-2 gap-2 whitespace-nowrap"
												onClick={() => {
													handleSelectImage("lowres_image");
												}}
											>
												<ImageIcon className="h-4 w-4" />
												Seleccionar de multimedia
											</Button>
										</div>
									</div>
									{errors?.lowres_image?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.lowres_image.message}</span>
									)}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								reset();
								navigate("/short_videos/list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading || isSubmitting}>
							{(isLoading || isSubmitting) && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear Short
						</Button>
					</div>
				</form>
			</div>
			<MultimediaImagePickerModal
				open={openDialog}
				onDismiss={() => {
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
				tabIndex={10}
				folderNames={FOLDERS_NAMES_SHORTS}
				onSuccess={url => {
					setValue(type, url);
					setOpenDialog(false);
					dispatch(galleryActions.resetToInitial());
				}}
			/>
		</section>
	);
}

export default ShortsNew;
