import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
	EditShortsDataBodyType,
	GetShortsParamsList,
	InsertShortsDataBodyType,
	editShorts,
	getShorts,
	insertShorts,
} from "../../../data-access/shorts/shorts";
import {auth} from "../../../firebase";

export interface shortsInterface {
	loading: boolean;
	totalResults: number;
	pageSize: number;
	current: number;
	results: shortResult[];
}

export interface shortResult {
	id: string;
	title: string;
	serie_title?: string;
	order: number;
	ranking: number;
	new: boolean;
	estreno?: boolean;
	published: boolean;
	short_description: string;
	long_description: string;
	video_id: string;
	duration: number;
	total_likes: number;
	total_dislikes: number;
	lowres_image: string;
	rectangular_image: string;
	audit_created?: string;
	audit_updated?: string;
	link: string;
	release_date: string;
	slugs?: string;
	tags?: shortTag[];
}

export interface shortTag {
	short_videos_tags_id: string;
	id: string;
	tag: string;
}

const initialState: shortsInterface = {
	loading: false,
	totalResults: 0,
	pageSize: 0,
	current: 0,
	results: [],
};

const getShortsList = createAsyncThunk("short_videos/list", async ({params}: {params?: GetShortsParamsList}, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return thunkAPI.rejectWithValue("No token");

		const response = await getShorts(token, params);

		return {
			totalResults: response.data.totalResults,
			pageSize: response.data.pageSize,
			current: response.data.current,
			results: response.data.results,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching shorts");
	}
});

const insertShort = createAsyncThunk("short_videos/insert", async (params: InsertShortsDataBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await insertShorts(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem inserting shorts");
	}
});

const updateShort = createAsyncThunk("short_videos/edit", async (params: EditShortsDataBodyType, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const response = await editShorts(token, params);

		return response.data;
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem updating shorts");
	}
});

const shortsSlice = createSlice({
	name: "short_videos",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getShortsList.pending, state => {
				state.loading = true;
			})
			.addCase(getShortsList.fulfilled, (state, action) => {
				state.loading = false;
				state.current = action.payload.current;
				state.pageSize = action.payload.pageSize;
				state.totalResults = action.payload.totalResults;
				state.results = [...action.payload.results];
			})
			.addCase(getShortsList.rejected, (state, action) => {});
	},
});

export const shortsActions = {...shortsSlice.actions, getShortsList, insertShort, updateShort};

export default shortsSlice;
