import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

/*INSERT STRIPE*/
export interface InsertCoinProductStripeBody {
	productid: string;
	images?: [string];
}
interface InsertCoinProductStripeResponse {
	id: string;
	name: string;
	description: string;
	price: number;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	stripe_productid: string;
	google_id: string;
	apple_id: string;
	active: boolean;
	audit_created: string;
	audit_updated: string;
}

export async function insertCoinProductStripeData(
	token: string,
	data: InsertCoinProductStripeBody,
): Promise<AxiosResponse<InsertCoinProductStripeResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/create_stripe_product_coins", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

export async function uploadProductStripeImage(token: string, data: FormData) {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.postForm("/upload_product_image", data, {
		headers: {
			Authorization: idToken,
		},
	});
}
