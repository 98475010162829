import axios, {AxiosResponse} from "axios";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export interface CoinProduct {
	id: string;
	name: string;
	description: string;
	price: number;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	stripe_productid: string;
	google_id: string;
	apple_id: string;
	active: boolean;
	audit_created: string;
	audit_updated: string;
}

/*INSERT*/
export interface InsertCoinProductBody {
	name: string;
	description: string;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	coins: number;
	active: boolean;
}
interface InsertCoinProductResponse {
	id: string;
	name: string;
	description: string;
	price: number;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	stripe_productid: string;
	google_id: string;
	apple_id: string;
	active: boolean;
	audit_created: string;
	audit_updated: string;
}
export async function insertCoinProductData(token: string, data: InsertCoinProductBody): Promise<AxiosResponse<InsertCoinProductResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.post("/create_database_product_coins", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*UPDATE*/
export interface EditCoinProductBody {
	productid: string;
	name: string;
	description: string;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	coins: number;
	active: boolean;
}
interface EditCoinProductResponse {
	id: string;
	name: string;
	description: string;
	price: number;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	stripe_productid: string;
	google_id: string;
	apple_id: string;
	active: boolean;
	audit_created: string;
	audit_updated: string;
}
export async function editCoinProductData(token: string, data: EditCoinProductBody): Promise<AxiosResponse<EditCoinProductResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.patch("/update_database_product_coins", data, {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
	});
}

/*LIST*/
export interface GetCoinProductListParams {
	page: number;
	page_size: number;
	searchby?: "id";
	searchvalue?: string;
	active?: boolean;
}
interface GetCoinProductListResponse {
	totalResults: number;
	pageSize: number;
	current: number;
	results: CoinProduct[];
}
export async function getCoinProductsList(token: string, params?: GetCoinProductListParams): Promise<AxiosResponse<GetCoinProductListResponse>> {
	const idToken = `Bearer ${token}`;
	return await axiosHandler.get("/admin_list_products_coins", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
