import useCurrentUserRole from "../../components/hooks/useCurrentUserRole";
import Contacts from "../customer-service-pages/Contacts";
import {AdminDashboard, InfluencerDashboard, PartnerDashboard, SponsorDashboard} from "./role-based";
import CorporateDashboard from "./role-based/corporate";
import {MarketingDashboard} from "./role-based/marketing";

function Dashboard() {
	const currentUserRole = useCurrentUserRole();

	switch (currentUserRole) {
		case "admin":
			return <AdminDashboard />;
		case "partner":
			return <PartnerDashboard />;
		case "sponsor":
			return <SponsorDashboard />;
		case "influencer":
			return <InfluencerDashboard />;
		case "customer_service":
			return <Contacts />;
		case "marketing":
			return <MarketingDashboard />;
		case "corporate":
			return <CorporateDashboard />;
		default:
			return null;
	}
}

export default Dashboard;
