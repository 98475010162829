import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../../components/primitives/icons/Spinner";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {useForm, SubmitHandler} from "react-hook-form";
import {coinProductsActions} from "../../_store/features/products/coin-products-slice";
import {Input} from "../../components/primitives/Input";
import {Textarea} from "../../components/primitives/Textarea";
import {Button} from "../../components/primitives/Button";

interface IFormInputs {
	name: string;
	description: string;
	coins: number;
	stripe_price: number;
	google_price: number;
	apple_price: number;
	active: boolean;
}
const initialState: IFormInputs = {
	name: "",
	description: "",
	coins: 0,
	stripe_price: 1,
	google_price: 1,
	apple_price: 1,
	active: false,
};

export function CoinProductsNew() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.coinProducts.loading);
	const isSuccess = useAppSelector(state => state.coinProducts.success);
	const error = useAppSelector(state => state.coinProducts.error);
	const [isSubmitted, SetIsSubmitted] = useState(false);

	const {
		register,
		formState: {errors},
		handleSubmit,
	} = useForm<IFormInputs>({
		defaultValues: initialState,
	});

	useEffect(() => {
		if (!isSuccess || !isSubmitted) return;
		dispatch(coinProductsActions.setSuccess(false));
		alert("PRODUCTO INSERTADO CORRECTAMENTE.");
		navigate("/products/coin-list");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	useEffect(() => {
		if (!isLoading && isSubmitted) {
			if (error) {
				alert("ERROR: " + error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const onSubmit: SubmitHandler<IFormInputs> = data => {
		SetIsSubmitted(true);
		dispatch(coinProductsActions.insertCoinProducts(data));
	};

	return (
		<div className="px-5 py-4">
			<div className="border-b border-b-border bg-background px-5 pb-5">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-4xl">Insertar Producto de Coins</h2>
				<span className="text-base text-muted-foreground lg:text-lg">Permite crear productos de coins para toda la plataforma.</span>
			</div>

			<div className="px-5">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<dl className="divide-y divide-border">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nombre<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="text"
										placeholder="Insertar nombre del producto coin..."
										{...register("name", {required: "Campo requerido"})}
									/>
									{errors.name && <span className="text-red-500">{errors.name.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Descripción<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Textarea
										rows={3}
										placeholder="Descripción del producto..."
										{...register("description", {required: "Campo requerido"})}
									/>
									{errors?.description?.message && (
										<span className="text-sm font-medium text-destructive">{errors?.description?.message}</span>
									)}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Monedas<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										{...register("coins", {
											required: "Campo requerido",
											min: {
												value: 1,
												message: "Valor fuera de rango",
											},
										})}
									/>
									{errors.coins && <span className="text-red-500">{errors.coins.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Precio Stripe (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										{...register("stripe_price", {
											required: "Campo requerido",
											min: {
												value: 1,
												message: "Valor fuera de rango",
											},
										})}
									/>
									{errors.stripe_price && <span className="text-red-500">{errors.stripe_price.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Precio Google (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										{...register("google_price", {
											required: "Campo requerido",
											min: {
												value: 1,
												message: "Valor fuera de rango",
											},
										})}
									/>
									{errors.google_price && <span className="text-red-500">{errors.google_price.message}</span>}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Precio Apple (valor en centavos)<span className="text-xs font-bold text-destructive">*</span>
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									<Input
										type="number"
										{...register("apple_price", {
											required: "Campo requerido",
											min: {
												value: 1,
												message: "Valor fuera de rango",
											},
										})}
									/>
									{errors.apple_price && <span className="text-red-500">{errors.apple_price.message}</span>}
								</dd>
							</div>
						</dl>
					</div>
					<div className="flex justify-end gap-2 pb-4">
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								navigate("/products/coin-list");
							}}
						>
							Cancelar
						</Button>
						<Button type="submit" variant="blueBtn" disabled={isLoading}>
							{isLoading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
							Crear producto
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
