import * as React from "react";
import {format, getUnixTime, startOfDay, endOfDay, subMonths} from "date-fns";
import {DateRange, Matcher} from "react-day-picker";
import {Button} from "../../primitives/Button";
import {Popover, PopoverContent, PopoverTrigger} from "../../primitives/Popover/popover";
import {cn} from "../../../../utils/classNames";
import {Calendar as CalendarIcon} from "../../primitives/icons";
import {Calendar} from "../../primitives/Calendar/calendar";
import {zonedTimeToUtc} from "date-fns-tz";

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
	onDateRangeChange?: (startDate: number, endDate: number, date: DateRange | undefined) => void;
	disabled?: Matcher | Matcher[];
	date?: DateRange | undefined;
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const INITIAL_DATE = undefined;

export function DatePickerWithRange({className, disabled, onDateRangeChange, date}: DatePickerWithRangeProps) {
	const [open, setOpen] = React.useState(false);
	const [localDate, setLocalDate] = React.useState<DateRange | undefined>(INITIAL_DATE);

	const handleConfirm = () => {
		if (localDate && localDate?.from) {
			const from = zonedTimeToUtc(startOfDay(localDate.from), timeZone);
			let to = localDate?.to ?? from;
			to = zonedTimeToUtc(endOfDay(to), timeZone);

			if (typeof onDateRangeChange === "function") {
				onDateRangeChange(getUnixTime(from), Number(getUnixTime(to)), localDate);
				resetLocalDate();
				setOpen(false);
			}
		}
	};

	const resetLocalDate = () => {
		setLocalDate(INITIAL_DATE);
	};

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn("m-w-[300px] mx-auto justify-start text-center font-normal", !date && "text-muted-foreground")}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{date?.from ? (
							date.to ? (
								<>
									{format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
								</>
							) : (
								format(date.from, "LLL dd, y")
							)
						) : (
							<span>Seleccione un rango de fecha</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-2" align="start" onInteractOutside={resetLocalDate}>
					<Calendar
						showOutsideDays={false}
						mode="range"
						defaultMonth={subMonths(new Date(), 1)}
						selected={localDate}
						onSelect={setLocalDate}
						numberOfMonths={2}
						disabled={disabled}
					/>
					<div className="flex items-center justify-center space-x-4">
						<Button onClick={resetLocalDate} className="mt-2 flex flex-col justify-center" variant="outline" disabled={!localDate}>
							Reiniciar selección
							{localDate?.from ? (
								<span className="text-[10px]">
									{localDate.to ? (
										<>
											{format(localDate.from, "LLL dd, y")} - {format(localDate.to, "LLL dd, y")}
										</>
									) : (
										format(localDate.from, "LLL dd, y")
									)}
								</span>
							) : null}
						</Button>
						<Button onClick={handleConfirm} className="mt-2">
							Confirmar
						</Button>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
}
