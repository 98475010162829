import {useMemo} from "react";
import {ROLES} from "../../data-access/fetch-db-users";
import {dbUser} from "../../_store/features/user-db/user-db-slice";

export default function useUserRole(userInfo?: dbUser) {
	const value = useMemo(() => {
		let activeRole = null;

		if (!userInfo) {
			return activeRole;
		}

		const {admin, master_editor, editor, partner, sponsor, influencer, customer_service, marketing, corporate} = userInfo;
		const availableRoles = Object.entries({
			admin,
			master_editor,
			editor,
			partner,
			sponsor,
			influencer,
			customer_service,
			marketing,
			corporate,
		}) as [ROLES, boolean | null][];

		for (let [role, status] of availableRoles) {
			if (status) {
				activeRole = role;
			}
		}

		return activeRole;
	}, [userInfo]);

	return value;
}
